export default {
	cinema: {
		address: 'просп. Победы, 1Ж',
		place: null,
		phoneNumbers: ['Автоинформатор +7 (84635) 77-0-77', 'Касса +7 (927) 658-48-41', 'Боулинг +7 (84635) 77-0-33'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/club112279266',
		twitter: null,
		odnoklassniki: 'https://ok.ru/group/54669838516452',
		youtube: 'https://www.youtube.com/channel/UCSfU7Y9Iu8knFpPgteDKmvw',
		app: {
			appstore: null,
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.parallel',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CGw-qI2m',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
